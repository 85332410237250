
.dice_results {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  background: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dice_results__top {
  width: 100%;
}

.dice_results__top .dice_results__top__inner {
  width: calc(100% - 80px);
  margin: 0 auto;
}

.dice_results__top .dice_results__top__inner__ctas {
  margin: 4vh 0 0 0;
  display: flex;
  flex-direction: row;
}

.dice_results__top__inner__ctas button {
  font-family: 'cubano', 'Helvetica', verdana, arial, sans-serif;
  text-transform: uppercase;
  display: inline;
  border-radius: 10px;
  font-size: 20px;
  font-weight: bold;
  padding: 8px 12px;
  font-weight: 900;
  
}

.dice_results__top button.btn-give {
  background: #ffffff;
  color: #000000;
  white-space: nowrap;
}

.dice_results__top button.btn-roll {
  background: #fde700;
  color: #000000;
}


div.dice_results__top.bg-yellow button.btn-give,
div.dice_results__top.bg-yellow button.btn-roll{
  box-sizing: border-box;
  border: 4px solid #000;  
}

div.dice_results__top.bg-black button.btn-give,
div.dice_results__top.bg-black button.btn-roll{
  box-sizing: border-box;
  border: 4px solid #fff;  
}
div.dice_results__top.bg-black button.btn-roll{
  border: 4px solid #fde700;  

}

.dice_results__top h1 {
  text-align: left;
  text-transform: uppercase;
  margin: 0 0 3vh 0;
  font-size: 65px;
  line-height: 52px;
  /* white-space: nowrap; */
}

.dice_results__top h1.long {
  font-size: 40px;
  line-height: 40px;
}

.dice_results__top p {
  margin: 0;
}

.dice_results__top .bg-yellow h1,
.dice_results__top .bg-yellow p {
  color: #000000;
}

.dice_results__top .bg-black h1 {
  color: #fde700;
}
.dice_results__top .bg-yellow p {
  color: #000000;
}

.dice_results__top p {
  text-align: left;
}

.dice_results__bottom {
  width: 100%;
}

.dice_results__bottom img {
  width: 100%;
  height: auto;
}

.dice_results {
  display: flex;
  flex-direction: column;;
  box-sizing: border-box;
  height: 88vh;
  justify-content: flex-start;
}

.dice_results__top{
  flex-grow: 1;
  display: flex;
  flex-direction: column;;
  justify-content: center;;
}

.dice_results__bottom{

  /* border: 1px solid blue; */
  box-sizing: border-box;

}

.short-gif{
  display: none;
}

.standard-gif{
  display: block;
}


@media screen and (max-height: 780px) { 

  .dice_results__top h1 {
    font-size: 58px;
    margin: 0 0 3.25vh 0;
  }

  .short-gif{
    display: block;
  }
  
  .standard-gif{
    display: none;
  }

  .dice_results__top .dice_results__top__inner__ctas {
    margin: 3.15vh 0 0 0;
  }

}

@media screen and (max-width: 360px) { 
  .dice_results__top__inner__ctas button {
    font-size: 18px;
  }
}

@media screen and (max-width: 280px) { 
  .dice_results__top__inner__ctas button {
    font-size: 9px;
  }
}