
main.block-desktop {
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  height: 100%;

}


main.block-desktop img {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  border: 2px solid black;
  box-sizing: border-box;
  padding: 0 40px;
  position: relative;
  top: -2px;
}

